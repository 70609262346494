var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"450"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-4",staticStyle:{"min-height":"200px"},attrs:{"flat":""}},[_c('v-card-subtitle',{staticClass:"primary--text text-center"},[_vm._v(" Generate and download transactions report ")]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-card-subtitle',{staticClass:"primary--text py-0 px-0"},[_vm._v("Select Range")]),_c('v-select',{attrs:{"outlined":"","dense":"","items":[
                { text: 'Today', value: 'today' },
                { text: 'This Week', value: 'this_week' },
                { text: 'This Month', value: 'this_month' },
                { text: 'All time', value: 'all_time' },
                { text: 'Custom range', value: 'custom' }
              ]},model:{value:(_vm.filter.range),callback:function ($$v) {_vm.$set(_vm.filter, "range", $$v)},expression:"filter.range"}})],1),(_vm.filter.range == 'custom')?_c('v-col',{staticClass:"pt-0"},[_c('v-card-subtitle',{staticClass:"primary--text py-0 px-0"},[_vm._v("Start Date")]),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-text-field',_vm._g({staticStyle:{"background":"#fff","height":"40px"},attrs:{"append-icon":"event_note","outlined":"","dense":"","value":_vm.filter.start_date,"placeholder":"Start Date","readonly":"","color":"primary","rules":[function (v) { return !!v || 'Please select a date'; }]}},on))]}}],null,false,3889952188),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"light":"","color":"primary"},on:{"change":function($event){_vm.menu = false}},model:{value:(_vm.filter.start_date),callback:function ($$v) {_vm.$set(_vm.filter, "start_date", $$v)},expression:"filter.start_date"}})],1)],1):_vm._e(),(_vm.filter.range == 'custom')?_c('v-col',{staticClass:"pt-0"},[_c('v-card-subtitle',{staticClass:"primary--text py-0 px-0"},[_vm._v("End Date")]),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-text-field',_vm._g({staticStyle:{"background":"#fff","height":"40px"},attrs:{"append-icon":"event_note","outlined":"","dense":"","value":_vm.filter.end_date,"placeholder":"End Date","readonly":"","color":"primary","rules":[function (v) { return !!v || 'Please select a date'; }]}},on))]}}],null,false,408348220),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"light":"","color":"primary"},on:{"change":function($event){_vm.menu1 = false}},model:{value:(_vm.filter.end_date),callback:function ($$v) {_vm.$set(_vm.filter, "end_date", $$v)},expression:"filter.end_date"}})],1)],1):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"px-0 mt-3"},[_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"primary--text",attrs:{"block":"","elevation":"0","color":"grey lighten-3"},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("Close")])],1),_c('v-col',[_c('v-btn',{attrs:{"elevation":"0","block":"","color":"primary","loading":_vm.loading,"loading-text":"Please wait"},on:{"click":function($event){_vm.$refs.form.validate() ? _vm.generateDownload() : null}}},[_vm._v("Download")])],1)],1)],1)],1)],1),_c('download-excel',{staticStyle:{"opacity":"0","position":"absolute"},attrs:{"fetch":_vm.downloadReport,"before-finish":_vm.finishDownload,"fields":_vm.report_data,"name":"POS Transaction Report.xls","type":"xls","id":"downloadBtn"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }