<template>
  <div>
    <v-dialog
      max-width="450"
      v-model="dialog"
    >
      <v-card
        class="pa-4"
        flat
        style="min-height: 200px;"
      >
        <v-card-subtitle class="primary--text text-center">
          Select date range below and click filter
        </v-card-subtitle>
        <v-form lazy-validation ref="form">
          <v-row>
            <v-col>
              <v-card-subtitle class="primary--text py-0 px-0">Start Date</v-card-subtitle>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    append-icon="event_note"
                    outlined
                    dense
                    :value="filter.start_date"
                    placeholder="Start Date"
                    readonly
                    v-on="on"
                    color="primary"
                    style="background: #fff; height: 40px"
                    :rules="[(v) => !!v || 'Please select a date']"
                  ></v-text-field>
                </template>
                <v-date-picker
                  light
                  v-model="filter.start_date"
                  @change="menu = false"
                  color="primary"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col>
              <v-card-subtitle class="primary--text py-0 px-0">End Date</v-card-subtitle>
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    append-icon="event_note"
                    outlined
                    dense
                    :value="filter.end_date"
                    placeholder="End Date"
                    readonly
                    v-on="on"
                    color="primary"
                    style="background: #fff; height: 40px"
                    :rules="[(v) => !!v || 'Please select a date']"
                  ></v-text-field>
                </template>
                <v-date-picker
                  light
                  v-model="filter.end_date"
                  @change="menu1 = false"
                  color="primary"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions class="px-0 mt-3">
          <v-row>
            <v-col>
              <v-btn
                block
                elevation="0"
                color="grey lighten-3"
                class="primary--text"
                @click="closeDialog()"
              >Close</v-btn>
            </v-col>
            <v-col>
              <v-btn
                elevation="0"
                block
                color="primary"
                :loading="loading"
                loading-text="Please wait"
                @click="$refs.form.validate() ? filterResult() : null"
              >Filter</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data: () => {
    return {
      dialog: false,
      menu: false,
      menu1: false,
      filter: {
        start_date: null,
        end_date: null,
      },
      loading: false,
      user_id: null,
      terminal_id: null,
    }
  },

  computed: {
    query() {
      let filter = {
        start_date: this.filter.start_date,
        end_date: this.filter.end_date,
        target: 'transactions',
        user_id: this.user_id,
        terminal_id: this.terminal_id,
      }
      return filter
    }
  },

  mounted() {
    
  },

  methods: {
    ...mapActions('pos', [
      'filtertransactions',
    ]),

    openDialog(value) {
      value.user_id ? this.user_id = value.user_id
      : (value.terminal_id ? this.terminal_id = value.terminal_id : '')
      this.dialog = true
    },

    closeDialog() {
      this.dialog = false
    },

    async filterResult() {
      this.loading = true
      let url = ''
      switch (this.query.target) {
        case 'transactions':
          let data = {
            start_date: this.query.start_date,
            end_date: this.query.end_date,
            target: this.query.target,
            user_id: this.user_id,
            terminal_id: this.terminal_id
          }
          url = await this.filtertransactions(data)
          break;
      }
      try {
        let response = url
        this.loading = false
        switch (this.query.target) {
          case 'transactions':
            this.$root.$emit('filteredtransactions', response.data)
        }
        this.closeDialog()
        this.$refs.form.reset()
      } catch (error) {
        this.loading = false
      }
    }
  }
}
</script>