<template>
  <div>
    <v-dialog
      max-width="450"
      v-model="dialog"
    >
      <v-card
        class="pa-4"
        flat
        style="min-height: 200px;"
      >
        <v-card-subtitle class="primary--text text-center">
          Generate and download transactions report
        </v-card-subtitle>
        <v-form lazy-validation ref="form">
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-card-subtitle class="primary--text py-0 px-0">Select Range</v-card-subtitle>
              <v-select
                outlined
                dense
                v-model="filter.range"
                :items="[
                  { text: 'Today', value: 'today' },
                  { text: 'This Week', value: 'this_week' },
                  { text: 'This Month', value: 'this_month' },
                  { text: 'All time', value: 'all_time' },
                  { text: 'Custom range', value: 'custom' }
                ]"
              ></v-select>
            </v-col>

            <v-col v-if="filter.range == 'custom'" class="pt-0">
              <v-card-subtitle class="primary--text py-0 px-0">Start Date</v-card-subtitle>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    append-icon="event_note"
                    outlined
                    dense
                    :value="filter.start_date"
                    placeholder="Start Date"
                    readonly
                    v-on="on"
                    color="primary"
                    style="background: #fff; height: 40px"
                    :rules="[(v) => !!v || 'Please select a date']"
                  ></v-text-field>
                </template>
                <v-date-picker
                  light
                  v-model="filter.start_date"
                  @change="menu = false"
                  color="primary"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col v-if="filter.range == 'custom'" class="pt-0">
              <v-card-subtitle class="primary--text py-0 px-0">End Date</v-card-subtitle>
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    append-icon="event_note"
                    outlined
                    dense
                    :value="filter.end_date"
                    placeholder="End Date"
                    readonly
                    v-on="on"
                    color="primary"
                    style="background: #fff; height: 40px"
                    :rules="[(v) => !!v || 'Please select a date']"
                  ></v-text-field>
                </template>
                <v-date-picker
                  light
                  v-model="filter.end_date"
                  @change="menu1 = false"
                  color="primary"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions class="px-0 mt-3">
          <v-row>
            <v-col>
              <v-btn
                block
                elevation="0"
                color="grey lighten-3"
                class="primary--text"
                @click="closeDialog()"
              >Close</v-btn>
            </v-col>
            <v-col>
              <v-btn
                elevation="0"
                block
                color="primary"
                :loading="loading"
                loading-text="Please wait"
                @click="$refs.form.validate() ? generateDownload() : null"
              >Download</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <download-excel
      style="opacity: 0; position: absolute;"
      :fetch="downloadReport"
      :before-finish="finishDownload"
      :fields="report_data"
      name="POS Transaction Report.xls"
      type="xls"
      id="downloadBtn"
    ></download-excel>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      dialog: false,
      menu: false,
      menu1: false,
      loading: false,
      transactions: null,
      filter: {
        range: 'today',
        start_date: null,
        end_date: null,
      },
      report: [],
      report_data: {
        "Agent": "pos.user.name",
        "Terminal ID": "terminalId",
        "Serial Number": "pos.serialNumber",
        "Reference": "reference",
        "Amount": "amount",
        "Type": "pos.pos_type",
        "PAN": "pan",
        "RRN": "rrn",
        "STAN": "stan",
        "MERCHANT ID": 'merchantId',
        "Commission": {
          field: "commission_amount",
          callback: (value) => {
            return this.$options.filters.formatPrice(value)
          }
        },
        "Status": {
          field: "reversal",
          callback: (value) => {
            return value == 0 ? 'Approved' : 'Declined'
          }
        },
        Date: {
          field: "transactionDate",
          callback: (value) => {
            return this.$options.filters.formatDate(value)
          }
        },
      },
    }
  },

  computed: {
    query() {
      let filter = {
        start_date: this.filter.start_date,
        end_date: this.filter.end_date,
        filter: this.filter.range,
        terminal_id: this.transactions.terminal_id,
        user_id: this.transactions.user_id,
        target: this.transactions.target
      }
      return filter
    }
  },

  mounted() {
    
  },

  methods: {
    ...mapActions('pos', [
      'downloadreport'
    ]),

    // startDownload(){
    //     this.$toast.success('Generating your files, Please wait...')
    // },

    finishDownload(){
        this.$toast.success('Downloading file...')
    },

    openDialog(value) {
      this.transactions = value
      this.dialog = true
    },

    closeDialog() {
      this.dialog = false
    },

    generateDownload() {
      document.getElementById('downloadBtn').click()
    },

    async downloadReport() {
      this.loading = true
      try{
        let response = await this.downloadreport(this.query)
        this.loading = false
        this.closeDialog()
        this.$refs.form.reset()
        this.filter.start_date = null
        this.filter.end_date = null
        return this.report = response.data.transactions
      } catch (error) {
        this.loading = false
      }
    }
  }
}
</script>