<template>
  <v-container class="px-5 mb-10">
    <div class="pa-3 pa-md-0">
      <div v-if="loadPage">
        <div class="text-center" style="height: 60vh; margin-top: 30vh;">
          <mini-loader class="text-center"></mini-loader>
          <p class="primary--text text-body-1">Fetching Terminal details...</p>
        </div>
      </div>
      <div v-if="!loadPage">
        <v-row>
          <v-col cols="12" sm="5" md="3" class="py-0">
            <div class="primary--text">
              <v-btn
                text
                style="background: #BEBFC6;"
                class="rounded-0 white--text rounded"
                small
                @click="$router.go(-1)"
              >
                <v-icon class="mr-1" size="15">arrow_back</v-icon> Back
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-row class="mt-2">
              <v-col cols="2" class="py-0"><span class="teller-subtitle">Teller:</span></v-col>
              <v-col cols="10" class="py-0"><span class="teller-text">{{ (agent && agent.user) ? agent.user.name : null }}</span></v-col>
            </v-row>
            <v-row class="py-0">
              <v-col cols="2" class="py-0"><span class="teller-subtitle">Terminal ID:</span></v-col>
              <v-col cols="10" class="py-0"><span class="teller-text">{{ $route.params.terminal_id }}</span></v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="2"><span class="teller-subtitle">Serial Number:</span></v-col>
              <v-col cols="10"><span class="teller-text">{{ agent ? agent.serialNumber : null }}</span></v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mb-5 mt-5">
          <v-col md="3">
            <v-card
              elevation="2"
              class=""
            >
              <v-card-text class="primary--text font-weight-medium">Total Transactions</v-card-text>
              <v-card-title class="primary--text pt-0 text-subtitle-1">{{ total_transactions }} | ₦ {{ total_amount | formatPrice }}</v-card-title>
            </v-card>
          </v-col>
          <v-col md="3">
            <v-card
              elevation="2"
              class=""
            >
              <v-card-text class="primary--text font-weight-medium">This Month's Transactions</v-card-text>
              <v-card-title class="primary--text pt-0 text-subtitle-1">{{ monthly_transactions }} | ₦ {{ monthly_amount | formatPrice }}</v-card-title>
            </v-card>
          </v-col>
          <v-col md="3">
            <v-card
              elevation="2"
              class=""
            >
              <v-card-text class="primary--text font-weight-medium">This Weeks's Transactions</v-card-text>
              <v-card-title class="primary--text pt-0 text-subtitle-1">{{ weekly_transactions }} | ₦ {{ weekly_amount | formatPrice }}</v-card-title>
            </v-card>
          </v-col>
          <v-col md="3">
            <v-card
              elevation="2"
              class=""
            >
              <v-card-text class="primary--text font-weight-medium">Today's Transactions</v-card-text>
              <v-card-title class="primary--text pt-0 text-subtitle-1">{{ daily_transactions }} | ₦ {{ daily_amount | formatPrice }}</v-card-title>
            </v-card>
          </v-col>
          <!-- <v-col>
            <v-card
              flat
              class="text-center"
            >
              <v-card-text>Target</v-card-text>
              <v-chip style="background: #625A42;" label class="white--text">Behind</v-chip>
            </v-card>
          </v-col> -->
        </v-row>

        <v-row class="mt-4">
          <v-card-text class="primary--text text-h6 py-0">Transactions</v-card-text>
          <v-col>
            <v-btn
              text
              :class="today ? 'primary mr-4' : 'inactive mr-4'"
              @click="filterToday()"
              id="today"
            >Today</v-btn>

            <v-btn
              text
              @click="filterWeek()"
              :class="this_week ? 'primary mr-4' : 'inactive mr-4'"
            >This Week</v-btn>

            <v-btn
              text
              @click="filterMonth()"
              :class="this_month ? 'primary mr-4' : 'inactive mr-4'"
            >This Month</v-btn>

            <v-btn
              text
              @click="filterAll()"
              :class="all_time ? 'primary mr-4' : 'inactive mr-4'"
            >Since Launch</v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" class="mt-0">
            <v-text-field
              outlined
              label="Search..."
              append-icon="search"
              v-model="search"
              color="primary"
              dense
              style="background: #fff; height: 40px"
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-btn
              elevation="0"
              color="#E7E7ED"
              class="primary--text py-5"
              @click="openFilterDialog({ 'terminal_id': $route.params.terminal_id })"
            ><v-icon>hourglass_bottom</v-icon> Filter result</v-btn>
            <filter-dialog ref="filter"></filter-dialog>
          </v-col>

          <v-row justify="end" align="center">
            <v-btn
              text
              class="inactive pa-5 mr-6"
              @click="openReportDialog()"
            >Generate Receipt</v-btn>
          </v-row>
        </v-row>

        <terminal-transactions ref="terminal_transactions" :search="search" :transactions="all_transactions"></terminal-transactions>

        <report-dialog ref="report"></report-dialog>

      </div>
    </div>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import TerminalTransactions from '@/components/pos/terminal_transactions'
import MiniLoader from '@/components/MiniLoader'
import FilterDialog from '@/components/pos/dialogs/filterDialog'
import ReportDialog from '@/components/pos/dialogs/reportDialog'
export default {
  components: {
    TerminalTransactions,
    MiniLoader,
    FilterDialog,
    ReportDialog,
  },
  layout: "dashboard",
  transition: "default",
  data() {
    return {
      today: true,
      this_week: false,
      this_month: false,
      all_time: false,
      search: '',
      loadPage: false,
      date_menu: false,
      loading: false,
      agent: {},
      terminals: true,
      transactions: false,
      // pos_requests: false,
      assign_teller: false,
      pos_terminals: false,
      selected: [],
      all_requests: [],
      terminal: { text: 'Terminal 1', value: 1 },
      menu1: false,
      dialog: false,
      form: {
        type: null,
      },
      all_transactions: [],
      balance: 0,
      total_transactions: 0,
      total_amount: 0,
      daily_transactions: 0,
      daily_amount: 0,
      weekly_amount: 0,
      monthly_amount: 0,
      weekly_transactions: 0,
      monthly_transactions: 0,
      filter_time: '',
    };
  },

  mounted() {
    this.terminalTransactions();
    setTimeout(() => {
      document.getElementById('today').click();
    }, 3000);
    window.scrollTo(0, 0);
  },

  methods: {
    ...mapActions('pos', [
      'terminaltransactions'
    ]),

    filterToday() {
      this.today       = true;
      this.this_week   = false;
      this.this_month  = false;
      this.all_time    = false;
      this.filter_time = 'today';
      if (this.filter_time !== '') {
        let filter_data = {
          range: this.filter_time,
          terminal_id: this.$route.params.terminal_id
        };
        this.terminals ? this.$refs.terminal_transactions.filterTransactions(filter_data) : null
      }
    },

    filterWeek() {
      this.this_week   = true
      this.today       = false
      this.this_month  = false
      this.all_time    = false
      this.filter_time = 'this_week'
      if (this.filter_time !== '') {
        let filter_data = {
          range: this.filter_time,
          terminal_id: this.$route.params.terminal_id
        }
        this.terminals ? this.$refs.terminal_transactions.filterTransactions(filter_data) : null
      }
    },

    filterMonth() {
      this.this_month = true
      this.today      = false
      this.this_week  = false
      this.all_time   = false
      this.filter_time = 'this_month'
      if (this.filter_time !== '') {
        let filter_data = {
          range: this.filter_time,
          terminal_id: this.$route.params.terminal_id
        }
        this.terminals ? this.$refs.terminal_transactions.filterTransactions(filter_data) : null
      }
    },

    filterAll() {
      this.all_time   = true
      this.today      = false
      this.this_week  = false
      this.this_month = false
      this.filter_time = 'all_time'
      if (this.filter_time !== '') {
        let filter_data = {
          range: this.filter_time,
          terminal_id: this.$route.params.terminal_id
        }
        this.terminals ? this.$refs.terminal_transactions.filterTransactions(filter_data) : null
      }
    },

    async terminalTransactions() {
      this.loadPage = true
      try {
        let response              = await this.terminaltransactions(this.$route.params.terminal_id)
        this.loadPage             = false
        this.agent                = Object.assign({}, response.data.terminal)
        // this.balance              = response.data.balance
        // this.total_transactions   = response.data.totalTxCount
        // this.total_amount         = response.data.totalTxAmount
        // this.daily_transactions   = response.data.dailyTxCount
        // this.daily_amount         = response.data.dailyTxAmount
        // this.all_transactions     = response.data.transactions
        // this.weekly_amount        = response.data.weekly_amount
        // this.monthly_amount       = response.data.monthly_amount
        // this.weekly_transactions  = response.data.weekly_transactions
        // this.monthly_transactions = response.data.monthly_transactions
        // this.all_transactions     = response.data.transactions
      } catch (error) {
        this.loadPage = false
      }
    },

    openReportDialog() {
      let data = {}
      this.terminals ? data.terminal_id = this.$route.params.terminal_id : null
      data.target = 'terminal_transactions'
      this.$refs.report.openDialog(data)
    },

    openFilterDialog(value) {
      this.$refs.filter.openDialog(value)
    }
  },

  computed: {
    ...mapGetters("auth", ["user"]),

    tabName() {
      let tabName = ''
      this.terminals
      ? tabName = 'Terminals'
      : this.transactions
      ? tabName = 'Transactions'
      // : this.requests
      // ? tabName = 'POS Requests'
      : this.assign_teller
      ? tabName = 'Assign Teller'
      : ''

      return tabName
    },
  }
};
</script>
<style lang="scss">
.v-application .elevation-5 {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25) !important;
}
.v-application .elevation-2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.1),
    0px 2px 2px 0px rgba(0, 0, 0, 0.07), 0px 1px 5px 0px rgba(0, 0, 0, 0.06) !important;
}
.v-application .elevation-0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0),
    0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}
.v-menu__content {
  box-shadow: 0 0.04rem 0.4rem rgba(0, 0, 0, 0.15) !important;
  border: 1px solid #dddddd;
}
.t-color tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.inactive {
  background: #ffffff !important;
  color: #171B70 !important;
}

.myTable table thead {
  background: #E7E7ED;
}
table thead tr th {
  color: #171B70 !important;
}
.teller-subtitle {
  color: #7A7A7A;
  font-size: 12px;
}
.teller-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 27px;
  color: #171B70;
}
</style>